@font-face {
  font-family: "Raleway-Bold";
  src: url("assets/fonts/Raleway/Raleway-Bold.ttf");
}
@font-face {
  font-family: "Raleway-Italic";
  src: url("assets/fonts/Raleway/Raleway-Italic.ttf");
}
@font-face {
  font-family: "Raleway-Light";
  src: url("assets/fonts/Raleway/Raleway-Light.ttf");
}
@font-face {
  font-family: "Raleway-Medium";
  src: url("assets/fonts/Raleway/Raleway-Medium.ttf");
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("assets/fonts/Raleway/Raleway-Regular.ttf");
}
@font-face{
  font-family: "InterTight-ExtraLight";
  src: url("assets/fonts/Inter/InterTight-ExtraLight.ttf");
}
@font-face{
  font-family: "InterTight-Medium";
  src: url("assets/fonts/Inter/InterTight-Medium.ttf");
}
@font-face{
  font-family: "InterTight-Regular";
  src: url("assets/fonts/Inter/InterTight-Regular.ttf");
}
@font-face{
  font-family: "InterTight-Light";
  src: url("assets/fonts/Inter/InterTight-Light.ttf");
}
@font-face{
  font-family: "InterTight-Medium";
  src: url("assets/fonts/Inter/InterTight-Medium.ttf");
}
@font-face{
  font-family: "InterTight-SemiBold";
  src: url("assets/fonts/Inter/InterTight-SemiBold.ttf");
}
@font-face{
  font-family: "InterTight-Bold";
  src: url("assets/fonts/Inter/InterTight-Bold.ttf ");
}



.Raleway-Bold {

  font-family: "Raleway-Bold" !important;
}

.Raleway-Italic {
  font-family: "Raleway-Italic" !important;
}

.Raleway-Light {
  font-family: "Raleway-Light" !important;
}

.Raleway-Medium {
  font-family: "Raleway-Medium" !important;
}

.Raleway-Regular {
  font-family: "Raleway-Regular" !important;
}

/* 
====================================================================
  Loading Transition
====================================================================

 */
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #000000bd;
}
.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
  text-align: center;
}
.ctn-preloader .animation-preloader .icon {
  display: inline-block;
  position: relative;
}
.ctn-preloader .animation-preloader .txt-loading {
  user-select: none;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #f9c9b3;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 30px;
  position: absolute;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: "Raleway-Regular";
  letter-spacing: 12px;
  display: inline-block;
  color: #ee6931;
  position: relative;
  font-size: 50px;
  line-height: 30px;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
}

.shadow {
   margin: 0 auto;
  height: 100px;
   &.bottom {
    width: 100%;
    box-shadow: 0px 10px 2px -10px #111;    
  }
}

.side-bar-scroll:-webkit-scrollbar {
  width: 7px;
}

.side-bar-scroll::-webkit-scrollbar-track {
  background: #3026f1;
}



.side-bar-scroll::-webkit-scrollbar-thumb {
  background: #191847;
  border-radius: 5px !important;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px #191847ae, 0px 4px 5px 0px #00000024,
    0px 1px 10px 0px #191847b4;
}

.body::-webkit-scrollbar {
  width: 7px;
}
body::-webkit-scrollbar-track {
  background: white;
}
body::-webkit-scrollbar-thumb {
  background: #3026f1;
  border-radius: 0;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 100;
}
.padding-0 {
  padding: 0px;
}
.padding-5 {
  padding: 5px;
}
.padding-10 {
  padding: 10px;
}
.padding-20 {
  padding: 20px;
}
.selected-row {
  /* border: 1px solid red; */
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}
.unselected-row {
  /* border: 1px solid red; */
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
td {
  border-bottom: none !important;
}
.text_feild_small {
  height: 36px !important;
}
.disabled-bg {
  background-color: #efefef !important;
}
/* .MuiOutlinedInput-root {
  height: 43px !important;
} */
.ps__rail-y {
  display: none !important;
}
.border-bottom-black {
  border-bottom: 1px solid #3e3e3e;
}

.update-my-info {
  background-color: #fff;
  color: #3e3e3e;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid #3e3e3e;
  padding: 10px;
  border-radius: 15px;
  margin: 10px;
  transition: 0.5s;
  font-family: "Raleway-Regular";
}

.update-my-info:hover {
  /* background-color: #f9d9b6;
  color: #ee6931; */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.102), 0 10px 10px rgba(0, 0, 0, 0.094);
  transition: 0.5s;
  /* transform: scale(1.01); */
}

/* .arrow {
  position: absolute;
  right: 0px;
  background: white;
} */
.MuiInputLabel-root {
  font-family: "Raleway-Regular" !important;
  font-size: 16px !important;
  color: black !important;
}

.pac-container {
  z-index: 9999;
}
.pac-icon {
  display: none !important;
}
.pac-matched {
  /* padding-right: 2px; */
  font-weight: 500;
  font-family: "Raleway-Bold";
  color: #ee6931;
  font-size: 16px;
}
.pac-item {
  padding: 5px;
  font-size: 16px;
}
.pac-item,
.pac-item-query {
  font-family: "Raleway-Medium";
  color: #000;
  font-size: 16px;
}
.map {
  /* Add transition for smooth animation */
  transition: transform 0.3s ease;
}



.tint {
  position: relative;
  float: left;
  cursor: pointer;
}

.tint:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,255,255, 0.5);
  /* transition: background .3s linear; */
}

.tint:hover:before {
  background: none;
}